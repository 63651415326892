<template>
  <b-modal
    v-model="visible"
    title-tag="h3"
    @hidden="close"
    hide-footer
    centered
    no-close-on-backdrop
    no-close-on-esc
    header-bg-variant="transparent"
    modal-class="custom-modal-amg"
    header-class="p-0"
  >
    <template v-slot:modal-header>
      <div class="header-modal d-flex justify-content-between">
        <h3 class="text-white m-0 font-weight-bold">
          Create Service
        </h3>
        <div>
          <feather-icon
            icon="XIcon"
            size="22"
            class="close-button"
            @click="close"
          />
        </div>
      </div>
    </template>
    <b-container>
      <b-row>
        <b-col>
          <b-form-group label="Account">
            <div class="d-flex flex-row flex-nowrap">
              <b-form-input
                v-model="client.account"
                type="text"
                placeholder="Search account..."
                style="
                  border-top-right-radius: 0;
                  border-bottom-right-radius: 0;
                "
                class="border-right-0"
                @keyup.enter="searchClient"
              />
              <b-button
                @click="searchClient"
                style="border-top-left-radius: 0; border-bottom-left-radius: 0"
                class="px-1 py-0"
                variant="primary"
                :disabled="client.account == ''"
              >
                <feather-icon icon="SearchIcon" size="20" />
              </b-button>
            </div>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Client">
            <b-form-input v-model="client.name" :disabled="true" />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="6">
          <b-form-group label="Service">
            <b-form-input
              v-model="typeServiceName[typeService]"
              :disabled="true"
              placeholder="Name service"
              class="font-weight-bolder"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6">
          <b-form-group label="Type">
            <v-select
              v-model="chargeSelected"
              :options="optionsCharges"
              :reduce="(option) => option.id"
              label="type"
              placeholder="--Select an option--"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Comment">
            <b-form-textarea v-model="comment" />
          </b-form-group>
        </b-col>
      </b-row>
      <div class="d-flex justify-content-end">
        <b-button
          :disabled="validateFields"
          variant="success"
          @click="sendApplication"
        >
          Create
        </b-button>
      </div>
      <br />
    </b-container>
  </b-modal>
</template>
<script>
import vSelect from "vue-select";
import { mapGetters, mapActions } from "vuex";
import ApplicationsService from "../../../services/applications.service";
export default {
  components: { vSelect },
  props: {
    fromDashboardSpecialist: {
      type: Boolean,
      default: false,
    },
    clientFromDashboard: {
      type: Object,
      default: () => {},
    },
    typeService: {
      type: Number,
      default: 1,
      require: false,
    },
  },
  data() {
    return {
      visible: false,
      client: {
        account: "",
        name: "",
        id: "",
      },
      chargeSelected: "",
      optionsCharges: [],
      comment: "",
      clientModuleId: null,
      typeServiceName: {
        1: "CREDIT CARD",
        2: "APPOINTMENT",
        3: "OTHER SERVICES",
      },
    };
  },
  methods: {
    ...mapActions({
      CHANGE_REFRESH_TABLE: "clients-store/CHANGE_REFRESH_TABLE",
    }),
    async start() {
      if(this.fromDashboardSpecialist) {
        this.clientModuleId = this.clientFromDashboard.module;
      }

      const params = {
        typeService: this.typeServiceName[this.typeService],
      };
      let charges = await ApplicationsService.getTypesCharges(params);
      charges.data.map((el) => {
        this.optionsCharges.push({
          type: el.description,
          id: el.id,
        });
      });
      if (this.fromDashboardSpecialist) {
        this.client = this.clientFromDashboard;
      }
    },
    async searchClient() {
      try {
        let client = await ApplicationsService.searchClientByAccount({
          account: this.client.account,
          id_role: this.currentUser.role_id,
          iduser: this.currentUser.user_id,
        });
        client.data.length == 0
          ? this.showWarningSwal("Enter a valid account", "Client not found")
          : (this.client.name = client.data[0].name_client);
        this.client.id = client.data[0].id;
        this.clientModuleId = client.data[0].module_id;
      } catch (error) {
        if (error.response) {
          if (error.response.status == 422) {
            this.showWarningSwal(error.response.data.errors.account[0]);
          } else {
            this.showErrorSwal(error);
          }
        }
      }
    },
    async sendApplication() {
      try {
        let data = {
          id_client: this.client.id,
          app_comment: this.comment,
          id_user: this.currentUser.user_id,
          type_charge: this.chargeSelected,
          module_id: this.clientModuleId,
          _request_work_plan_id: null,
          type_service_id: this.typeService,
        };

        let confirm = await this.showConfirmSwal();
        if (confirm.isConfirmed) {
          this.addPreloader();
          await ApplicationsService.saveApplication(data);

          this.showSuccessSwal("Succesful Operation", "Service was registered");
          this.close();
          this.$emit("refreshTable");
          await this.CHANGE_REFRESH_TABLE(true);
        }
        this.removePreloader();
      } catch (error) {
        this.removePreloader();
        this.showWarningSwal(error);
      }
    },
    close() {
      this.visible = false;
      this.$emit("close");
    },
    open() {
      this.visible = true;
    },
  },
  computed: {
    validateFields() {
      return !this.client.id || !this.chargeSelected;
    },
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
  },
  async created() {
    await this.start();
    this.open();
  },
};
</script>
