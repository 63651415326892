export const canNavigateDirect = (to, roles, eng_soft) =>
  to.matched.some((route) => {
    if (to.meta.permittedRoles) {
      if (to.meta.permittedRoles.length > 0) {
        const userModuls = roles.map((role) => role.module_id)
        if (!userModuls.includes(to.matched[0]?.meta?.module)) return false
        let roleId = roles.filter(
          (role) => role.module_id === to.matched[0]?.meta?.module
        )[0].role_id
        let levelId = roles.filter(
          (role) => role.module_id === to.matched[0]?.meta?.module
        )[0].typesenior
        for (let i = 0; i < roles.length; i++) {
          if (roles[i].module_id == to.matched[0]?.meta?.module) {
            return (
              roles[i].module_id == to.matched[0]?.meta?.module &&
              (to.meta.hasLevelValidation
                ? to.meta.permittedRoles.some(
                    (permittedRole) =>
                      permittedRole.role_id == roleId &&
                      (permittedRole.hasLevelValidation
                        ? permittedRole.level_id == levelId
                        : true)
                  )
                : to.meta.permittedRoles.includes(roleId))
            )
          }
        }
      } else {
        const userModuls = roles.map((role) => role.module_id)
        if (!userModuls.includes(to.matched[0]?.meta?.module)) return false
        if (route.meta.eng_soft && eng_soft === 1) {
          return true
        } else {
          return false
        }
      }
    } else {
      if (route.name === "amg-menu" || route.name === "projects-ceo" ||  route.name === "review-projects-ceo"|| route.name === "amg-messages" || route.name === "login-old-soft" || route.name === "log-reader" || route.name === "log-reader-nest" || route.name === "users-my-profile" || route.name === 'ui-feather' ||  route.name === 'ui-tabler') {
        return true
      } else {
        const userModuls = roles.map((role) => role.module_id)
        if (!userModuls.includes(to.matched[0]?.meta?.module)) return false
        return true
      }
    }
  })
export const _ = undefined