<template>
  <div>
    <header-slot />

    <div class="d-flex flex-row justify-content-end">
      <b-button
        v-if="moduleId == 29"
        size="lg"
        variant="outline-success"
        @click="openCreateApplicationModal"
      >
        Create
      </b-button>
    </div>
    <b-nav card-header pills class="m-0">
      <b-nav-item
        :to="{ name: $route.matched[1].meta.routePending }"
        exact
        :exact-active-class="statusApp == 1 ? 'active' : ''"
        :link-classes="['px-3', bgTabsNavs]"
      >
        PENDING
        <span
          v-if="G_PENDING_APPLICATIONS > 0"
          class="ml-2"
        >
          <feather-icon
            icon
            :badge="G_PENDING_APPLICATIONS > 99 ? '+99' : G_PENDING_APPLICATIONS"
            badge-classes="badge-danger"
          />
        </span>
      </b-nav-item>
      <b-nav-item
        :to="{ name: $route.matched[1].meta.routeCompleted }"
        exact
        :exact-active-class="statusApp == 2 ? 'active' : ''"
        :link-classes="['px-3', bgTabsNavs]"
        :active="isInCompletedTab"
        >COMPLETED
      </b-nav-item>
      <b-nav-item
        :to="{ name: $route.matched[1].meta.routeExpired }"
        exact
        :exact-active-class="statusApp == 4 ? 'active' : ''"
        :link-classes="['px-3', bgTabsNavs]"
        >EXPIRED
      </b-nav-item>
    </b-nav>
    <b-card
      no-body
      class="border-top-primary border-3 border-table-radius px-0"
    >
      <router-view
        :key="$route.name"
        :program_id="programId"
        @table="assignTable($event)"
      />
    </b-card>

    <create-application-modal
      v-if="ActiveCreateApplicationModal"
      @close="closeCreateApplicationModal"
      @refreshTable="refreshTable"
    />
  </div>
</template>
<script>
import TableApplications from "@/views/commons/components/applications/views/TableAplications.vue";
import CreateApplicationModal from "@/views/commons/components/applications/views/components/modals/CreateApplicationModal.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { canNavigateDirect } from "@/libs/acl/routeProtectionDirect";
import { getUserData } from "@/auth/utils";
import ApplicationService from '@/views/commons/components/applications/services/applications.service';

export default {
  beforeRouteEnter(to, from, next) {
    const userData = getUserData();
    if (!canNavigateDirect(to, userData.arrRoles, userData.eng_soft)) {
      return next({ name: "misc-not-authorized" });
    } else {
      return next();
    }
  },
  components: {
    TableApplications,
    CreateApplicationModal,
  },
  data() {
    return {
      ActiveCreateApplicationModal: false,
      table: null,
      moduleToProgram:{
        5: 4,
        6: 3,
        7: 2,
        20: 3,
        22: 3,
        29: null, 
      }
    };
  },
  methods: {
    openCreateApplicationModal() {
      this.ActiveCreateApplicationModal = true;
    },
    closeCreateApplicationModal() {
      this.ActiveCreateApplicationModal = false;
    },
    ...mapActions({
      getCountriesAction: "DebtSolutionApplications/getCountriesAction",
      A_GET_PENDING_APPLICATIONS: "DebtSolutionApplications/A_GET_PENDING_APPLICATIONS"
    }),
    assignTable(table) {
      this.table = table;
    },
    refreshTable() {
      this.table.refresh();
    },
    ...mapMutations({
      M_PENDING_APPLICATIONS: 'DebtSolutionApplications/M_PENDING_APPLICATIONS'
    })
  },
  computed: {
    moduleId() {
      return this.$route.matched[1].meta.module;
    },
    programId() {
      return this.$route.matched[1].meta.program;
    },
    statusApp() {
      return this.$route.meta.statusApp;
    },
    isInCompletedTab() {
      const { routeSuccessfull, routeUnsuccessful } =
        this.$route.matched[1].meta;
      return [routeSuccessfull, routeUnsuccessful].includes(this.$route.name);
    },
    ...mapGetters({
      currentUser: 'auth/currentUser',
      G_PENDING_APPLICATIONS: 'DebtSolutionApplications/G_PENDING_APPLICATIONS'
    })
  },
  async created() {
    this.getCountriesAction();
    await this.A_GET_PENDING_APPLICATIONS(this.moduleId);
  },
};
</script>
<style>
td {
  padding-top: 10px !important;
  padding-left: 10px !important;
  padding-bottom: 5px !important;
  padding-right: 0 !important;
}
.tab {
  transform: translateY(-1rem);
}
.container-fluid {
  padding: 0;
}
</style>
